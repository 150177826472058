import Common from "../common";
import { ABI } from "./StandardTokenABI";

const UsdtBep20Contract = {
  address: Common.isDevelopment()
    ? "0xeb59678497aaff6bdd5b3ec66abd3ddb1489c11b"
    : "0x55d398326f99059ff775485246999027b3197955",
  symbol: "USDT",
  decimals: 18,
  abi: ABI,
};

export { UsdtBep20Contract };
