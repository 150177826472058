import { createRouter, createWebHashHistory } from "vue-router";
import TopView from "../views/TopView.vue";

const routes = [
  {
    path: "/",
    name: "top",
    component: TopView,
  },
  {
    path: "/pairs",
    name: "home",
    // route level code-splitting
    // this generates a separate chunk (pairs.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/account",
    name: "account",
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/AccountView.vue"),
  },
  {
    path: "/network",
    name: "network",
    component: () =>
      import(/* webpackChunkName: "network" */ "../views/NetworkView.vue"),
  },
  {
    path: "/addWallet",
    name: "addWallet",
    component: () =>
      import(/* webpackChunkName: "addWallet" */ "../views/AddWalletView.vue"),
  },
  {
    path: "/createWallet",
    name: "createWallet",
    component: () =>
      import(
        /* webpackChunkName: "createWallet" */ "../views/CreateWalletView.vue"
      ),
  },
  {
    path: "/backupWallet",
    name: "backupWallet",
    component: () =>
      import(
        /* webpackChunkName: "backupWallet" */ "../views/BackupWalletView.vue"
      ),
  },
  {
    path: "/coldWallet",
    name: "coldWallet",
    component: () =>
      import(
        /* webpackChunkName: "coldWallet" */ "../views/ColdWalletView.vue"
      ),
  },
  {
    path: "/coldWallet2",
    name: "coldWallet2",
    component: () =>
      import(
        /* webpackChunkName: "coldWallet2" */ "../views/ColdWallet2View.vue"
      ),
  },
  {
    path: "/marketPlace",
    name: "marketPlace",
    component: () =>
      import(
        /* webpackChunkName: "marketPlace" */ "../views/MarketPlaceView.vue"
      ),
  },
  {
    path: "/marketLink",
    name: "marketLink",
    component: () =>
      import(
        /* webpackChunkName: "marketLink" */ "../views/MarketLinkView.vue"
      ),
  },
  {
    path: "/newsList",
    name: "newsList",
    component: () =>
      import(/* webpackChunkName: "newsList" */ "../views/NewsListView.vue"),
  },
  {
    path: "/news/:id",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/NewsView.vue"),
  },
  {
    path: "/assets/:network/:coin",
    name: "assets",
    component: () =>
      import(/* webpackChunkName: "assets" */ "../views/AssetsView.vue"),
  },
  {
    path: "/sendCoin/:network/:coin",
    name: "sendCoin",
    component: () =>
      import(/* webpackChunkName: "sendCoin" */ "../views/SendCoinView.vue"),
  },
  {
    path: "/receiveWallet/:network/:coin",
    name: "receiveWallet",
    component: () =>
      import(
        /* webpackChunkName: "receiveWallet" */ "../views/ReceiveWalletView.vue"
      ),
  },
  {
    path: "/security",
    name: "security",
    component: () =>
      import(/* webpackChunkName: "security" */ "../views/SecurityView.vue"),
  },
  {
    path: "/security",
    name: "security",
    component: () =>
      import(/* webpackChunkName: "security" */ "../views/SecurityView.vue"),
  },
  {
    path: "/passwordReset",
    name: "passwordReset",
    component: () =>
      import(
        /* webpackChunkName: "passwordReset" */ "../views/PasswordResetView.vue"
      ),
  },
  {
    path: "/passwordRelease",
    name: "passwordRelease",
    component: () =>
      import(
        /* webpackChunkName: "passwordRelease" */ "../views/PasswordReleaseView.vue"
      ),
  },
  {
    path: "/walletList",
    name: "walletList",
    component: () =>
      import(
        /* webpackChunkName: "walletList" */ "../views/WalletListView.vue"
      ),
  },
  {
    path: "/secretkey1",
    name: "secretkey1",
    component: () =>
      import(
        /* webpackChunkName: "secretkey1" */ "../views/Secretkey1View.vue"
      ),
  },
  {
    path: "/secretkey2",
    name: "secretkey2",
    component: () =>
      import(
        /* webpackChunkName: "secretkey2" */ "../views/Secretkey2View.vue"
      ),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/assetsStatus",
    name: "assetsStatus",
    component: () =>
      import(
        /* webpackChunkName: "assetsStatus" */ "../views/AssetsStatusView.vue"
      ),
  },
  {
    path: "/edit",
    name: "edit",
    component: () =>
      import(/* webpackChunkName: "edit" */ "../views/EditView.vue"),
  },
  {
    path: "/languageEdit",
    name: "languageEdit",
    component: () =>
      import(
        /* webpackChunkName: "languageEdit" */ "../views/LanguageEditView.vue"
      ),
  },
  {
    path: "/currencyEdit",
    name: "currencyEdit",
    component: () =>
      import(
        /* webpackChunkName: "currencyEdit" */ "../views/CurrencyEditView.vue"
      ),
  },
  {
    path: "/phrase1",
    name: "phrase1",
    component: () =>
      import(/* webpackChunkName: "phrase1" */ "../views/Phrase1View.vue"),
  },
  {
    path: "/phrase2",
    name: "phrase2",
    component: () =>
      import(/* webpackChunkName: "phrase2" */ "../views/Phrase2View.vue"),
  },
  {
    path: "/addToken",
    name: "addToken",
    component: () =>
      import(/* webpackChunkName: "addToken" */ "../views/AddTokenView.vue"),
  },
  {
    path: "/walletGuide",
    name: "walletGuide",
    component: () =>
      import(
        /* webpackChunkName: "walletGuide" */ "../views/WalletGuideView.vue"
      ),
  },
  {
    path: "/terms",
    name: "terms",
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/TermsView.vue"),
  },
  {
    path: "/dappTransactionTest",
    name: "dappTransactionTest",
    component: () =>
      import(
        /* webpackChunkName: "dappTransactionTest" */ "../views/DappTransactionTestView.vue"
      ),
  },
  {
    path: "/dappTransaction",
    name: "dappTransaction",
    component: () =>
      import(
        /* webpackChunkName: "dappTransaction" */ "../views/DappTransactionView.vue"
      ),
  },
  {
    path: "/dapp",
    name: "dapp",
    component: () =>
      import(/* webpackChunkName: "dapp" */ "../views/DappView.vue"),
  },
  {
    path: "/addressList",
    name: "addressList",
    component: () =>
      import(
        /* webpackChunkName: "addressList" */ "../views/AddressListView.vue"
      ),
  },
  {
    path: "/createAddress",
    name: "createAddress",
    component: () =>
      import(
        /* webpackChunkName: "createAddress" */ "../views/CreateAddressView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
