import Definitions from "@/js/definitions";
import { createStore } from "vuex";

export default createStore({
  state: {
    bitcoinWallet: null,
    bitcoinTestNetWallet: null,
    ethereumWallet: null,
    fantomWallet: null,
    tronWallet: null,
    bscWallet: null,
    currentNetwork: "",
  },
  getters: {
    currentWallet: (state) => (network) => {
      switch (network) {
        case Definitions.Networks.Bitcoin:
          return state.bitcoinWallet;

        case Definitions.Networks.BitcoinTestNet:
          return state.bitcoinTestNetWallet;

        case Definitions.Networks.Ethereum:
          return state.ethereumWallet;

        case Definitions.Networks.Fantom:
          return state.fantomWallet;

        case Definitions.Networks.Tron:
          return state.tronWallet;

        case Definitions.Networks.Bsc:
          return state.bscWallet;

        default:
          throw "not implemented in currentWallet.";
      }
    },
  },
  mutations: {
    setCurrentWallet(state, wallet) {
      switch (wallet.network) {
        case Definitions.Networks.Bitcoin:
          state.bitcoinWallet = wallet;
          break;

        case Definitions.Networks.BitcoinTestNet:
          state.bitcoinTestNetWallet = wallet;
          break;

        case Definitions.Networks.Ethereum:
          state.ethereumWallet = wallet;
          break;

        case Definitions.Networks.Fantom:
          state.fantomWallet = wallet;
          break;

        case Definitions.Networks.Tron:
          state.tronWallet = wallet;
          break;

        case Definitions.Networks.Bsc:
          state.bscWallet = wallet;
          break;

        default:
          throw "not implemented in setCurrentWallet.";
      }
    },
    setCurrentNetwork(state, network) {
      state.currentNetwork = network;
    },
  },
  actions: {},
  modules: {},
});
