import Common from "../common";
import { ABI } from "./StandardTokenABI";

const UsdtErc20Contract = {
  address: Common.isDevelopment()
    ? "0xeb59678497aaff6bdd5b3ec66abd3ddb1489c11b"
    : "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  symbol: "USDT",
  decimals: Common.isDevelopment() ? 18 : 6,
  abi: ABI,
};

export { UsdtErc20Contract };
