import router from "@/router";
import Definitions from "./definitions";

window.onload = () => {
  window.document.onmousedown = () => {
    localStorage.setItem(
      Definitions.StorageKeys.lastActiveTime,
      new Date().getTime()
    );
  };
};

const checkTimeout = () => {
  if (
    (!localStorage.getItem(Definitions.StorageKeys.appPassword) &&
      !localStorage.getItem(Definitions.StorageKeys.useBioOrFaceId)) ||
    !localStorage.getItem(Definitions.StorageKeys.autoLock) ||
    !localStorage.getItem(Definitions.StorageKeys.autoLockTimeLimit)
  ) {
    return;
  }
  const autoLockTimeLimit = localStorage.getItem(
    Definitions.StorageKeys.autoLockTimeLimit
  );
  const currentTime = new Date().getTime();
  const lastActiveTime = localStorage.getItem(
    Definitions.StorageKeys.lastActiveTime
  );
  const timeOut = autoLockTimeLimit * 1000;
  if (
    currentTime - lastActiveTime > timeOut &&
    (localStorage.getItem(Definitions.StorageKeys.appPassword) ||
      localStorage.getItem(Definitions.StorageKeys.useBioOrFaceId))
  ) {
    localStorage.removeItem(Definitions.StorageKeys.lastActiveTime);
    localStorage.setItem(Definitions.StorageKeys.locked, true);
    if (router.currentRoute.name == "login") {
      return;
    }
    router.push({ name: "login" });
  }
};

export default function () {
  window.setInterval(checkTimeout, 1000);
}
