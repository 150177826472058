import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./js/i18n/i18n";
import VueClipboard from "vue3-clipboard";
import Vant from "vant";
import "vant/lib/index.css";
import Definitions from "./js/definitions";
import lockApp from "./js/lockApp";
import LocalWallet from "./js/localWallet";
import { vfmPlugin } from "vue-final-modal";

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
  if (
    from.name == "login" &&
    localStorage.getItem(Definitions.StorageKeys.locked)
  ) {
    router.replace({
      name: "login",
    });
    return next();
  }
  if (
    to.name == "top" &&
    !from.name &&
    (localStorage.getItem(Definitions.StorageKeys.appPassword) ||
      localStorage.getItem(Definitions.StorageKeys.useBioOrFaceId))
  ) {
    router.replace({
      name: "login",
    });
    return next();
  }
  if (to.name == "top" && LocalWallet.getWallets()) {
    router.replace({
      name: "home",
    });
    return next();
  }
  return next();
});

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .use(Vant)
  .use(lockApp)
  .use(
    vfmPlugin({
      key: "$vfm",
      componentName: "VueFinalModal",
      dynamicContainerName: "ModalsContainer",
    })
  )
  .mount("#app");
