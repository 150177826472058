const Definitions = {
  Networks: {
    Bitcoin: "Bitcoin",
    BitcoinTestNet: "BitcoinTestNet",
    Ethereum: "Ethereum",
    Fantom: "Fantom",
    Tron: "Tron",
    Bsc: "Bsc",
  },
  StorageKeys: {
    language: "language",
    wallets: "wallets",
    tempWallet: "tempWallet",
    returnPath: "returnPath",
    appPassword: "appPassword",
    autoLock: "autoLock",
    autoLockTimeLimit: "autoLockTimeLimit",
    locked: "locked",
    lastActiveTime: "lastActiveTime",
    currentNetwork: "currentNetwork",
    tokens: "tokens",
    addressBook: "addressBook",
    useBioOrFaceId: "useBioOrFaceId",
  },
  BinanceSymbols: {
    BTCUSDT: "BTCUSDT",
    ETHUSDT: "ETHUSDT",
    FTMUSDT: "FTMUSDT",
    TRXUSDT: "TRXUSDT",
    BNBUSDT: "BNBUSDT",
  },
  AppTypes: {
    web: "web",
    android: "android",
    ios: "ios",
  },
};

export default Definitions;
