<template>
  <div class="wrapper" @touchstart="touchstart" @touchmove="touchmove">
    <!-- <transition mode="out-in">
      <router-view />
    </transition> -->
    <router-view />
  </div>
</template>
<script>
import router from "./router";
export default {
  mounted: function () {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");

    var ua = navigator.userAgent.toLowerCase();
    var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
    if (isiOS) {
      var viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        var viewportContent = viewport.getAttribute("content");
        viewport.setAttribute(
          "content",
          viewportContent + ", user-scalable=no"
        );
      }
    }
  },
  setup() {
    let touchStartX = 0;

    const touchstart = (e) => (touchStartX = e.touches[0].clientX);

    const touchmove = (e) => {
      if (e.touches[0].clientX - touchStartX > 100) {
        router.go(-1);
      }
    };

    return {
      touchstart,
      touchmove,
    };
  },
};
</script>
<style lang="less">
@import "./assets/css/style.css";
@import "./assets/css/custom.css";
// .v-enter-active,
// .v-leave-active {
//   transition: opacity 0.7s;
// }
// .v-enter-from,
// .v-leave-to {
//   opacity: 0;
// }
</style>
