import { Notify, Toast } from "vant";

const Common = {
  backendUrl: process.env.VUE_APP_BACKEND_URL.endsWith("/")
    ? process.env.VUE_APP_BACKEND_URL.slice(0, -1)
    : process.env.VUE_APP_BACKEND_URL,

  ethereumProvider: process.env.VUE_APP_ETHEREUM_PROVIDER,
  ropstenProvider:
    "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  fantomProvider: process.env.VUE_APP_FANTOM_PROVIDER,
  bscProvider: process.env.VUE_APP_BSC_PROVIDER,

  appType: process.env.VUE_APP_APPTYPE,

  newsUrl: process.env.VUE_APP_NEWS_URL,

  syncKey: process.env.VUE_APP_SYNC_KEY,

  isDevelopment: () => process.env.NODE_ENV == "development",

  success: (msg, position, onClose) => {
    return Notify({
      type: "success",
      message: msg,
      position: position ?? "bottom",
      onClose,
    });
  },

  error: (error, position, onClose) => {
    console.log(error);
    const msg = error.message ?? error;
    return Notify({
      type: "danger",
      message: msg,
      position: position ?? "bottom",
      onClose,
    });
  },

  toast: (msg, icon) =>
    icon
      ? Toast({
          message: msg,
          icon: icon,
        })
      : Toast(msg),

  toastFail: (msg) => Toast.fail(msg),

  utcFormat: (utc) => {
    let date = new Date(utc);
    let seperator1 = ".";
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let seconds = date.getSeconds();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    if (hour >= 0 && hour <= 9) {
      hour = "0" + hour;
    }
    if (minute >= 0 && minute <= 9) {
      minute = "0" + minute;
    }
    if (seconds >= 0 && seconds <= 9) {
      seconds = "0" + seconds;
    }
    let formatDate =
      year +
      seperator1 +
      month +
      seperator1 +
      strDate +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      seconds;
    return formatDate;
  },

  numberFormat: (number) => new Intl.NumberFormat().format(Number(number)),

  getDisplayAddress: (address, displayCount) => {
    let displayAddress = address;
    if (displayCount) {
      displayAddress = `${address.substring(
        0,
        displayCount
      )}...${address.substring(address.length - displayCount)}`;
    }
    return displayAddress;
  },

  getHost: (url) => {
    const index = String(url).indexOf("/", 8);
    if (index === -1) {
      return url;
    } else {
      return url.substring(0, index);
    }
  },

  getWebsiteImgUrl: (url) => `${Common.getHost(url)}/favicon.ico`,
};

export default Common;
