import store from "@/store";
import { UsdtBep20Contract } from "./contracts/BEP20Contracts";
import { UsdtErc20Contract } from "./contracts/ERC20Contracts";
import Definitions from "./definitions";
const LocalWallet = {
  getAllWallets: () => {
    let wallets = JSON.parse(
      localStorage.getItem(Definitions.StorageKeys.wallets)
    );
    return wallets;
  },

  updateAllWallets: (wallets) =>
    localStorage.setItem(
      Definitions.StorageKeys.wallets,
      JSON.stringify(wallets)
    ),

  getWallets: (network) => {
    let wallets = JSON.parse(
      localStorage.getItem(Definitions.StorageKeys.wallets)
    );
    if (network && wallets) {
      wallets = wallets.filter((x) => x.network === network);
    }
    return wallets;
  },

  setWallet: (wallet) => {
    if (wallet) {
      let localWallets = LocalWallet.getWallets();
      if (!localWallets) {
        localWallets = [];
      }
      const localWallet = localWallets.find(
        (x) => x.network === wallet.network && x.address === wallet.address
      );
      if (localWallet) {
        LocalWallet.setCurrentWallet(localWallet);
      } else {
        localWallets.push(wallet);
        localStorage.setItem(
          Definitions.StorageKeys.wallets,
          JSON.stringify(localWallets)
        );
        LocalWallet.setCurrentWallet(wallet);
      }
    }
  },

  getWallet: (network, address) => {
    const wallets = LocalWallet.getWallets();
    if (wallets) {
      return wallets.find(
        (x) => x.network === network && x.address === address
      );
    } else {
      return null;
    }
  },

  setCurrentWallet: (wallet) => {
    localStorage.setItem(
      generateWalletKey(wallet.network),
      JSON.stringify(wallet)
    );
    store.commit("setCurrentWallet", wallet);
  },

  getCurrentWallet: (network) =>
    JSON.parse(localStorage.getItem(generateWalletKey(network))),

  getTempWallet: () =>
    JSON.parse(sessionStorage.getItem(Definitions.StorageKeys.tempWallet)),

  setTempWallet: (wallet) =>
    sessionStorage.setItem(
      Definitions.StorageKeys.tempWallet,
      JSON.stringify(wallet)
    ),

  removeTempWallet: () =>
    sessionStorage.removeItem(Definitions.StorageKeys.tempWallet),

  setCurrentNetwork: (network) => {
    localStorage.setItem(Definitions.StorageKeys.currentNetwork, network);
    store.commit("setCurrentNetwork", network);
  },

  getCurrentNetwork: () =>
    localStorage.getItem(Definitions.StorageKeys.currentNetwork),

  addToken: (network, { address, symbol, decimals }) => {
    switch (network) {
      case Definitions.Networks.Ethereum:
        if (
          UsdtErc20Contract.address.toLocaleLowerCase() ===
          address.toLocaleLowerCase()
        ) {
          return;
        }
        break;

      case Definitions.Networks.Bsc:
        if (
          UsdtBep20Contract.address.toLocaleLowerCase() ===
          address.toLocaleLowerCase()
        ) {
          return;
        }
        break;

      default:
        return;
    }
    let savedTokensString = localStorage.getItem(
      Definitions.StorageKeys.tokens
    );
    let savedTokens = null;
    if (savedTokensString) {
      savedTokens = JSON.parse(savedTokensString);
    }
    if (!savedTokens) {
      savedTokens = {};
    }
    if (!savedTokens[network]) {
      savedTokens[network] = [];
    }
    const existToken = savedTokens[network].find(
      (x) => x.address.toLowerCase() === address.toLowerCase()
    );
    if (!existToken) {
      savedTokens[network].push({ address, symbol, decimals });
    }
    savedTokensString = JSON.stringify(savedTokens);
    localStorage.setItem(Definitions.StorageKeys.tokens, savedTokensString);
  },

  removeToken: (network, address) => {
    let savedTokensString = localStorage.getItem(
      Definitions.StorageKeys.tokens
    );
    let savedTokens = null;
    if (savedTokensString) {
      savedTokens = JSON.parse(savedTokensString);
    }
    if (savedTokens && savedTokens[network]) {
      let newNetworkTokens = [];
      savedTokens[network].forEach((token) => {
        if (token.address.toLocaleLowerCase() !== address.toLocaleLowerCase()) {
          newNetworkTokens.push(token);
        }
      });
      savedTokens[network] = newNetworkTokens;
      savedTokensString = JSON.stringify(savedTokens);
      localStorage.setItem(Definitions.StorageKeys.tokens, savedTokensString);
    }
  },

  getTokens: (network) => {
    const savedTokensString = localStorage.getItem(
      Definitions.StorageKeys.tokens
    );
    let savedTokens = null;
    if (savedTokensString) {
      savedTokens = JSON.parse(savedTokensString);
      if (savedTokens[network]) {
        return savedTokens[network];
      } else {
        return [];
      }
    } else {
      return [];
    }
  },

  getToken: (network, address) => {
    const savedTokensString = localStorage.getItem(
      Definitions.StorageKeys.tokens
    );
    let savedTokens = null;
    if (savedTokensString) {
      savedTokens = JSON.parse(savedTokensString);
      return savedTokens[network].find(
        (x) => x.address.toLocaleLowerCase() === address.toLocaleLowerCase()
      );
    } else {
      return null;
    }
  },
};

const generateWalletKey = (network) => `wallet${network}`;

export default LocalWallet;
