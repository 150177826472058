<template>
  <div>
    <div class="contents home-page">
      <div class="fv">
        <div class="inner">
          <h1>
            <span>{{ $t("ようこそ、新時代のEDOへ") }} </span><br />
            <span><b>EDOLUTION</b></span>
          </h1>
          <!-- <div class="img">
            <img src="../assets/img/top-fv-img.png" alt="" />
            <p>
              <b>EDO JIDAI</b><br />
              江戸時代（えどじだい）は、日本の歴史のうち江戸幕府（徳川幕府）の統治時代を指す時代区分である。
            </p>
          </div> -->
          <img
            src="../assets/img/top-cloud1-img.png"
            class="cloud1 updown1"
            alt=""
          />
          <img
            src="../assets/img/top-cloud2-img.png"
            class="cloud2 updown2"
            alt=""
          />
          <img
            src="../assets/img/top-cloud2-img.png"
            class="cloud3 updown2"
            alt=""
          />
        </div>
        <div class="movie">
          <video
            :src="`${publicPath}movie.mp4`"
            autoplay
            muted
            loop
            playsinline
          ></video>
        </div>
      </div>
      <!-- ./fv -->
      <div class="section">
        <div class="inner">
          <div class="ttl">
            <h2>{{ $t("デジタル資産ウォレット") }}</h2>
            <p>
              {{
                $t(
                  "BTC、ETH、USDTなどの主流のパブリックチェーンをサポートしています。"
                )
              }}
            </p>
          </div>
          <ul class="list">
            <li class="list1">
              <router-link
                :to="{ path: '/network', query: { hasAccount: true } }"
                class="link-over"
                >{{ $t("既にアカウントをお持ちの方") }}</router-link
              >
            </li>
            <li class="list2">
              <router-link to="/network" class="link-over">{{
                $t("アカウントをお持ちで無い方")
              }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- ./section1 -->
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import Footer from "@/components/Footer.vue";

export default {
  name: "TopView",
  components: {
    // Footer,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
